import { lazy } from "react";
import { onlyCustomer } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ContractorsOrderRequestCreatePageContent = lazy(() => import("./ContractorsOrderRequestCreatePageContent"));

function ContractorsOrderRequestCreatePageInternal() {
    return (
        <DefaultPage>
            <ContractorsOrderRequestCreatePageContent />
        </DefaultPage>
    );
}

export const ContractorsOrderRequestCreatePage = withAuthorization(onlyCustomer)(
    ContractorsOrderRequestCreatePageInternal
);
