import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CustomersPageContent = lazy(() => import("./CustomersPageContent"));

function CustomersPageInternal() {
    return (
        <DefaultPage>
            <CustomersPageContent />
        </DefaultPage>
    );
}

export const CustomersPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER, Role.EMPLOYEE)
)(CustomersPageInternal);
