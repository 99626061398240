import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CreateCreditNotePageContent = lazy(() => import("./CreateCreditNotePageContent"));

function CreateCreditNotePageInternal() {
    return (
        <DefaultPage>
            <CreateCreditNotePageContent />
        </DefaultPage>
    );
}

export const CreateCreditNotePage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER)
)(CreateCreditNotePageInternal);
