import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const OfferDetailsPageContent = lazy(() => import("./OfferDetailsPageContent"));

function OfferDetailsPageInternal() {
    return (
        <DefaultPage>
            <OfferDetailsPageContent />
        </DefaultPage>
    );
}

export const OfferDetailsPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER, Role.EMPLOYEE, Role.TEMPORARY_WORKER)
)(OfferDetailsPageInternal);
