import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ReceiptMenuPageContent = lazy(() => import("./ReceiptMenuPageContent"));

function ReceiptMenuPageInternal() {
    return (
        <DefaultPage>
            <ReceiptMenuPageContent />
        </DefaultPage>
    );
}

export const ReceiptMenuPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT))(
    ReceiptMenuPageInternal
);
