import {
    DATEV_MAX_CLIENT_NUMBER,
    DATEV_MAX_CONSULTANT_NUMBER,
    DATEV_MIN_CLIENT_NUMBER,
    DATEV_MIN_CONSULTANT_NUMBER,
    DATEV_MIN_CREDITOR_ACCOUNT_NUMBER,
    DATEV_MIN_DEBITOR_ACCOUNT_NUMBER,
    DatevProperties,
} from "@farmact/model/src/model/DatevProperties";
import { Vertex } from "@farmact/model/src/model/Vertex";
import dayjs from "dayjs";
import { SingleValidationError, Validator } from "./customHooks/validation/useValidation";
import { getOrderOfMagnitude } from "./numberUtils";

const NOT_EMPTY_STRING = "invalid_empty_string";

export function notEmptyString(value?: any) {
    const isValid = !!value && typeof value === "string";
    return isValid ? undefined : NOT_EMPTY_STRING;
}

const NOT_EMPTY_ARRAY = "invalid_empty_array";

export function notEmptyArray(value?: any[]) {
    const isValid = !!value && value.length > 0;
    return isValid ? undefined : NOT_EMPTY_ARRAY;
}

export function getRevenueAccountNumberValidator(currentDebitorAccountNumber: number | null, optional: boolean) {
    const message = `Bitte gib ein gültiges Umsatzkonto ein${optional ? " oder lasse das Feld frei" : ""}.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (!Number.isInteger(value)) {
            return message;
        }
        if (
            currentDebitorAccountNumber &&
            Number.isInteger(currentDebitorAccountNumber) &&
            getOrderOfMagnitude(currentDebitorAccountNumber) <= getOrderOfMagnitude(value)
        ) {
            return "Das Debitorenkonto muss mindestens eine Stelle länger sein als das Umsatzkonto.";
        }
    };
}

export function getCreditorAccountNumberValidator(optional: boolean) {
    const message = `Bitte gib ein gültiges Lieferantenkonto ein${optional ? " oder lasse das Feld frei" : ""}.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (!Number.isInteger(value)) {
            return message;
        }
        if (value < DATEV_MIN_CREDITOR_ACCOUNT_NUMBER) {
            return message;
        }
    };
}

export function getDebitorAccountNumberValidator(currentRevenueAccountNumber: number | null, optional: boolean) {
    const message = `Bitte gib ein gültiges Debitorenkonto (mindestens fünfstellig) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (!Number.isInteger(value)) {
            return message;
        }
        if (value < DATEV_MIN_DEBITOR_ACCOUNT_NUMBER) {
            return message;
        }
        if (
            currentRevenueAccountNumber &&
            Number.isInteger(currentRevenueAccountNumber) &&
            getOrderOfMagnitude(currentRevenueAccountNumber) >= getOrderOfMagnitude(value)
        ) {
            return "Das Debitorenkonto muss mindestens eine Stelle länger sein als das Umsatzkonto.";
        }
    };
}

export function getConsultantNumberValidator(optional: boolean) {
    const message = `Bitte gib eine gültige Beraternummer (1001 - 9999999) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (value < DATEV_MIN_CONSULTANT_NUMBER || value > DATEV_MAX_CONSULTANT_NUMBER || !Number.isInteger(value)) {
            return message;
        }
    };
}

export function getClientNumberValidator(optional: boolean) {
    const message = `Bitte gib eine gültige Mandantennummer (1 - 99999) ein${
        optional ? " oder lasse das Feld frei" : ""
    }.`;
    return (value: number | null) => {
        if (value === null) {
            return optional ? undefined : message;
        }
        if (value < DATEV_MIN_CLIENT_NUMBER || value > DATEV_MAX_CLIENT_NUMBER || !Number.isInteger(value)) {
            return message;
        }
    };
}

type GetReverseChargeAccountValidatorOptions = {
    optional?: boolean;
};
export function getReverseChargeAccountValidator(
    debitorAccount: DatevProperties["defaultDebitorAccount"],
    options: GetReverseChargeAccountValidatorOptions = {}
): Validator<DatevProperties["reverseChargeAccount"]> {
    return value => {
        if (value === null) {
            return options.optional ? undefined : "Bitte gib ein gültiges Reverse-Charge-Konto ein";
        }
        if (!Number.isInteger(value)) {
            return "Bitte gib ein gültiges Reverse-Charge-Konto ein";
        }
        if (
            debitorAccount &&
            Number.isInteger(debitorAccount) &&
            getOrderOfMagnitude(debitorAccount) <= getOrderOfMagnitude(value)
        ) {
            return "Das Debitorenkonto muss mindestens eine Stelle länger sein als das Reverse-Charge-Konto.";
        }
    };
}

const DATE_NOT_AFTER = "invalid_date_not_after";
const INVALID_DATE_FORMAT = "invalid_date_format";

/**
 * Creates a curried validation function that checks if the passed `earlierDate` is before
 * the date passed to the returned function
 * @param earlierDate
 * @returns
 * @example
 * const validateIsAfter2020 = isDateAfter(new Date("2020-31-01"))
 * validateIsAfter2020(new Date("2025-01-01")) // undefined
 * @example
 * const validateIsAfter2021 = isDateAfter(new Date("2021-31-01"))
 * validateIsAfter2021(new Date("2005-10-06")) // "invalid_date_not_after"
 */
export function isDateAfter(earlierDate?: Date | string | number | null) {
    return (laterDate?: Date | string | number | null) => {
        const mustBeAfterDate = dayjs(earlierDate);
        if (!mustBeAfterDate.isValid()) {
            return;
        }

        const date = dayjs(laterDate);
        if (!date.isValid()) {
            return INVALID_DATE_FORMAT;
        }

        if (!date.isAfter(mustBeAfterDate)) {
            return DATE_NOT_AFTER;
        }
    };
}

const NUMBER_NOT_GREATER_THAN = "number_not_greater_than";

export function isNumberGreaterThan(greaterThan: number, equal = false) {
    return (value?: number) => {
        if (value === undefined) {
            return NUMBER_NOT_GREATER_THAN;
        }

        if (value === greaterThan) {
            return equal ? undefined : NUMBER_NOT_GREATER_THAN;
        }

        if (value < greaterThan) {
            return NUMBER_NOT_GREATER_THAN;
        }
    };
}

const INVALID_GEOLOCATION = "invalid_geolocation";

export function isInvalidGeolocation(latLng?: Vertex) {
    const isValid = !!latLng && latLng.lat >= -90 && latLng.lat <= 90 && latLng.lng >= -180 && latLng.lng <= 180;
    return isValid ? undefined : INVALID_GEOLOCATION;
}

// found here: https://gist.github.com/Fedik/f050c65fa6cc93973fc65df9d00357f5
export function isValidBic(bic: string) {
    return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(bic.toUpperCase());
}

export function defined(value: unknown): SingleValidationError {
    if (!value) {
        return "must-be-defined";
    }
}
