import { getContractors } from "@farmact/customer-portal-api-client";
import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { Contractor } from "@farmact/model/src/model/Contractor";
import { User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useCustomClaims } from "@/components/authentication/useCustomClaims";
import { useCustomerApiErrorTranslation } from "@/util/customHooks/useCustomerApiErrorTranslation";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

export function useContractorContextData(authUser: User | null | undefined) {
    const { handleError } = useCustomerApiErrorTranslation();
    const [customClaims] = useCustomClaims(authUser);

    const customerIds = customClaims?.customerIds;
    const companyIds: string[] = customerIds ? Object.keys(customerIds) : [];

    const [contractors, setContractors] = useState<Contractor[]>([]);
    const [loading, setLoading] = useState(false);
    const stableCompanyIdArray = useStableIdArray(companyIds);

    useEffect(() => {
        const getContractorsFromApi = async () => {
            setLoading(true);
            const contractors = await getContractors();
            setContractors(
                contractors.map(
                    contractor =>
                        new Contractor({
                            ...contractor,
                            variant: contractor.variant as Variant,
                        })
                )
            );
        };
        try {
            if (stableCompanyIdArray.length > 0) {
                getContractorsFromApi();
            }
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    }, [stableCompanyIdArray, handleError]);

    return { contractors, loading };
}
