import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";
import { ProvideTollPageContext } from "./TollPageContext/ProvideTollPageContext";

const TollPageContent = lazy(() => import("./TollPageContent"));

function TollPageInternal() {
    return (
        <ProvideTollPageContext>
            <DefaultPage>
                <TollPageContent />
            </DefaultPage>
        </ProvideTollPageContext>
    );
}

export const TollPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER))(
    TollPageInternal
);
