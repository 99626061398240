import * as Routes from "@farmact/model/src/constants/farmActAppRoutes";
import { Variant } from "@farmact/model/src/model/CompanyMeta";
import { Role } from "@farmact/model/src/model/Role";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { SubMenu } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenu/SubMenu";
import { SubMenuItem } from "@/components/structure/Page/SideNav/SideMenuTile/subMenus/SubMenuItem/SubMenuItem";
import { useRole } from "@/util/customHooks/useRole";
import "./organizationMenu.scss";

type OrganizationMenuProps = {
    isOpen?: boolean;
    isInSplitPane: boolean;
};

export const OrganizationMenu = (props: OrganizationMenuProps) => {
    const { companyMeta, operatingUnits } = useAppCompanyContext();
    const { only } = useRole();

    const isAdminOrOwner = only(Role.OWNER, Role.ADMIN);
    const isAtLeastAccountant = only(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT);
    const isAtLeastPlanner = only(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER);

    const operatingUnitsWithBrokenConnectedBanks = operatingUnits.filter(
        operatingUnit => operatingUnit.connectedBanksNeedAttention
    ).length;

    return (
        <SubMenu isOpen={props.isOpen} cssClass="organization-menu">
            {isAtLeastAccountant && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Mitarbeiter"
                    route={Routes.ORGANIZATION_EMPLOYEES}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Maschinen" route={Routes.ORGANIZATION_MACHINES} />
            {companyMeta?.variant === Variant.LOGISTICS && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Be-/Entladestellen"
                    route={Routes.ORGANIZATION_LOADING_POINTS}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Kunden" route={Routes.ORGANIZATION_CUSTOMERS} />
            {isAtLeastPlanner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Dienstleistungen"
                    route={Routes.ORGANIZATION_SERVICES}
                />
            )}
            <SubMenuItem isInSplitPane={props.isInSplitPane} name="Artikel" route={Routes.ORGANIZATION_RESOURCES} />
            {isAtLeastPlanner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Tankstellen"
                    route={Routes.ORGANIZATION_GASSTATIONS}
                />
            )}
            {isAtLeastPlanner && companyMeta?.datevEnabled && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Lieferanten"
                    route={Routes.ORGANIZATION_SUPPLIERS}
                />
            )}
            {isAdminOrOwner && (
                <SubMenuItem
                    isInSplitPane={props.isInSplitPane}
                    name="Mein Betrieb"
                    route={Routes.ORGANIZATION_COMPANY}
                    badgeNumber={isAdminOrOwner ? operatingUnitsWithBrokenConnectedBanks : undefined}
                />
            )}
        </SubMenu>
    );
};
