import { lazy } from "react";
import { onlyCustomer } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ContractorsOrderRequestDetailsPageContent = lazy(() => import("./ContractorsOrderRequestDetailsPageContent"));

function ContractorsOrderRequestPageInternal() {
    return (
        <DefaultPage>
            <ContractorsOrderRequestDetailsPageContent />
        </DefaultPage>
    );
}

export const ContractorsOrderRequestDetailsPage = withAuthorization(onlyCustomer)(ContractorsOrderRequestPageInternal);
