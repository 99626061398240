import { recordError } from "@/util/recordError";

export const getMessageForAuthError = (error: any) => {
    if (["auth/user-not-found", "auth/wrong-password"].includes(error.code)) {
        return "Das Passwort ist falsch oder dieser Nutzer existiert nicht.";
    }

    if ("auth/user-disabled" === error.code) {
        return "Dieser Account ist deaktiviert. Bitte kontaktiere unseren Support.";
    }

    if (["auth/invalid-email", "auth/missing-email"].includes(error.code)) {
        return "Die Email-Adresse ist ungültig.";
    }

    if ("auth/too-many-requests" === error.code) {
        return (
            "Dein Zugang wurde vorübergehend gesperrt, weil du dein Passwort zu oft falsch eingegeben hast. " +
            "Bitte versuche es später noch einmal. " +
            "Du kannst den Zugang sofort wiederherstellen, indem du dein Passwort zurücksetzt."
        );
    }

    if (["auth/network-request-failed", "unavailable"].includes(error.code)) {
        return "Die Internetverbindung ist gerade schwach. Bitte versuche es später noch einmal.";
    }

    recordError("Error while logging in.", { error });
    return (
        "Es ist ein Fehler bei der Anmeldung aufgetreten. " +
        "Bitte versuche es später erneut. " +
        "Wenn das Problem bestehen bleibt, wende dich bitte an unseren Support."
    );
};
