import { OperatingUnit } from "@farmact/model/src/model/OperatingUnit";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { useRef, useState } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { ChooseOperatingUnitModalProps } from "@/components/internalWork/ChooseOperatingUnitModal/ChooseOperatingUnitForInternalWorkModal";
import { Deferred } from "@/util/async";

type ChooseOperatingUnitReturn = {
    choose: () => Promise<TimeTracking["operatingUnitId"]>;
    /**
     * Props that should be passed to `<ChooseOperatingUnitForInternalWorkModal>`
     */
    modalProps: ChooseOperatingUnitModalProps;
};

// TODO: this component might be generalized to serve as a general operating unit choosing modal
export function useChooseOperatingUnitForInternalWorkModal(): ChooseOperatingUnitReturn {
    const { appCompany, defaultOperatingUnit, operatingUnits } = useAppCompanyContext();
    const { employee } = useUserContext();
    const [modalOpen, setModalOpen] = useState(false);
    const [operatingUnitsToChooseFrom, setOperatingUnitsToChooseFrom] = useState<OperatingUnit[]>([]);
    const deferred = useRef<Deferred<OperatingUnit["id"]>>();

    const chooseOperatingUnit = (): Promise<TimeTracking["operatingUnitId"]> => {
        if (!employee) {
            return Promise.resolve(null);
        }
        if (!appCompany?.settings.employeeSettings.queryOperatingUnitForInternalWork) {
            return Promise.resolve(defaultOperatingUnit?.id ?? null);
        }
        if (employee.operatingUnitIds.length === 1) {
            return Promise.resolve(employee.operatingUnitIds[0]);
        }

        const availableOperatingUnits = operatingUnits.filter(unit => employee.operatingUnitIds.includes(unit.id));

        deferred.current = new Deferred();
        setOperatingUnitsToChooseFrom(availableOperatingUnits);
        setModalOpen(true);
        return deferred.current.promise;
    };

    const handleSubmit = (operatingUnitId: OperatingUnit["id"]) => {
        deferred.current?.resolve(operatingUnitId);
        setModalOpen(false);
    };

    return {
        choose: chooseOperatingUnit,
        modalProps: {
            isOpen: modalOpen,
            onDismiss: () => setModalOpen(false),
            availableOperatingUnits: operatingUnitsToChooseFrom,
            onSubmit: handleSubmit,
        },
    };
}
