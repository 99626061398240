// rewrite OpenAPI BASE URL during development
import { OpenAPI as EInvoiceApi } from "@farmact/e-invoice-service-client";
import { environment } from "@/config/config";

// must be equal to the value in vite.config.ts
const localEInvoiceApiPath = "/localApi/e-invoices";

export function setupEInvoiceApi() {
    if (environment == "local") {
        EInvoiceApi.BASE = localEInvoiceApiPath;
    }

    if (environment === "dev" || environment === "alpha") {
        EInvoiceApi.BASE = "https://dev.api.invoices.farmact.de";
    }

    if (environment === "beta") {
        EInvoiceApi.BASE = "https://beta.api.invoices.farmact.de";
    }

    if (environment === "prod") {
        EInvoiceApi.BASE = "https://api.invoices.farmact.de";
    }
}
