import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const SuppliersPageContent = lazy(() => import("./SuppliersPageContent"));

function SuppliersPageInternal() {
    return (
        <DefaultPage>
            <SuppliersPageContent />
        </DefaultPage>
    );
}

export const SuppliersPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT))(
    SuppliersPageInternal
);
