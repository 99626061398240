"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchTransferService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class BatchTransferService {
    /**
     * Get all batch transfers
     * @returns BatchTransfersResponseDto
     * @throws ApiError
     */
    static getBatchTransfers({ companyId, operatingUnitId, receiptTypes, status, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/batch-transfers',
            query: {
                'receiptTypes': receiptTypes,
                'status': status,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Export all receipts within a timerange to DATEV
     * Export all receipts within a timerange to DATEV. PDFs and CSV content will be generated automatically from company metadata and receipt information. `dateFrom`: "YYYY-MM-DD", `dateTo`: "YYYY-MM-DD"
     * @returns BatchTransfersResponseDto
     * @throws ApiError
     */
    static createBatchTransfer({ dateFrom, dateTo, receiptTypes, exportingUsername, companyId, operatingUnitId, receiptStatus, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'POST',
            url: '/batch-transfers',
            query: {
                'dateFrom': dateFrom,
                'dateTo': dateTo,
                'receiptTypes': receiptTypes,
                'receiptStatus': receiptStatus,
                'exportingUsername': exportingUsername,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Get a batch transfer by id
     * @returns BatchTransferResponseDto
     * @throws ApiError
     */
    static getBatchTransfer({ id, companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/batch-transfers/{id}',
            path: {
                'id': id,
            },
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
}
exports.BatchTransferService = BatchTransferService;
