import { ApiError, Error } from "@farmact/customer-portal-api-client";
import { useCallback } from "react";
import { useToastContext } from "@/components/ToastContext";
import { recordError } from "@/util/recordError";

export function useCustomerApiErrorTranslation() {
    const { onMessage } = useToastContext();

    const handleError = useCallback(
        (error: any) => {
            const translateCustomerApiError = (error: string) => {
                const errorMessage = translator.translate(error, { useGenericErrors: true, useRawErrorFallback: true });
                onMessage(errorMessage, "warning");
            };
            const _error = (error as ApiError).body as Error.FError;
            recordError(error);
            translateCustomerApiError(_error.fullErrorCode);
        },
        [onMessage]
    );
    return { handleError };
}

const dictionary: Error.TranslationDictionary = {
    FCPA_400: "Da ist etwas schiefgelaufen. Bitte versuche es später erneut.",
    FCPA_400_01: "Da ist etwas schiefgelaufen. Bitte versuche es später erneut.",
    // FCPA_400_01_0001: "TODO: Bad Request. Cannot determine the companyId from the request",
    // FCPA_400_01_0002: "TODO: Bad Request. Cannot determine the userId from the token",
    FCPA_401: "Du hast nicht die nötigen Berechtigungen für diese Aktion.",
    FCPA_401_01: "Du hast nicht die nötigen Berechtigungen für diese Aktion.",
    // FCPA_401_01_0001: "TODO: Unauthorized. Cannot verify the token",
    FCPA_401_01_0002: "Dir fehlen Bearbeitungsrechte für diese Firma.",
    // FCPA_402: "TODO: Payment Required",
    FCPA_403: "Du hast keine Berechtigung für diese Aktion.",
    FCPA_403_01: "Du hast keine Berechtigung für diese Aktion.",
    FCPA_403_01_0001: "Du hast keine Zugang zu dieser Firma",
    FCPA_403_01_0002: "Dir fehlen bearbeitungsrechte für diese Firma. Wende dich an deinen Lohnunternehmer.",
    // FCPA_403_01_0003: "TODO: Forbidden. Customer has no access to any operatingUnitId",
    FCPA_403_10: "Du hast keine Berechtigung für diese Aktion.",
    // FCPA_403_10_0001: "TODO: Forbidden. Resource(s) not accessible by current customer",
    FCPA_403_20: "Du hast keine Berechtigung für diese Aktion.",
    FCPA_403_20_0001: "Die Anfrage kann nicht bearbeitet werden.",
    FCPA_403_20_0002: "Angenommen oder Abgelehnte Anfragen können nicht bearbeitet werden.",
    FCPA_404: "Da ist etwas schiefgelaufen. Bitte versuche es später erneut.",
    FCPA_404_02: "Da ist etwas schiefgelaufen. Bitte versuche es später erneut.",
    // FCPA_404_02_0001: "TODO: Not Found. Cannot find resource in firebase",
    FCPA_404_03: "Keine Position für eingegebene Adresse gefunden.",
    FCPA_404_03_0001: "Die eingetragene Adresse kann nicht gefunden werden.",
    // FCPA_405: "TODO: Method Not Allowed",
    // FCPA_406: "TODO: Not Acceptable",
    // FCPA_407: "TODO: Proxy Authentication Required",
    // FCPA_408: "TODO: Request Timeout",
    // FCPA_409: "TODO: Conflict",
    // FCPA_410: "TODO: Gone",
    // FCPA_411: "TODO: Length Required",
    // FCPA_412: "TODO: Precondition Failed",
    // FCPA_413: "TODO: Payload Too Large",
    // FCPA_414: "TODO: URI Too Long",
    // FCPA_415: "TODO: Unsupported Media Type",
    // FCPA_416: "TODO: Range Not Satisfiable",
    // FCPA_417: "TODO: Expectation Failed",
    // FCPA_418: "TODO: I`m a teapot (RFC 2324, RFC 7168)",
    // FCPA_421: "TODO: Misdirected Request",
    // FCPA_422: "TODO: Unprocessable Entity",
    // FCPA_423: "TODO: Locked (WebDAV; RFC 4918)",
    // FCPA_424: "TODO: Failed Dependency (WebDAV; RFC 4918)",
    // FCPA_425: "TODO: Too Early (RFC 8470)",
    // FCPA_426: "TODO: Upgrade Required",
    // FCPA_428: "TODO: Precondition Required (RFC 6585)",
    // FCPA_429: "TODO: Too Many Requests (RFC 6585)",
    // FCPA_431: "TODO: Request Header Fields Too Large (RFC 6585)",
    // FCPA_451: "TODO: Unavailable For Legal Reasons (RFC 7725)",
    // FCPA_500: "TODO: Internal Server Error",
    FCPA_500_02: "Unser Service ist derzeit nicht verfügbar. Bitte versuche es später erneut.",
    // FCPA_500_02_0001: "TODO: Internal Server Error. Something went wrong while getting resource(s) from firebase",
    // FCPA_500_02_0002: "TODO: Internal Server Error. Something went wrong while creating resource(s) in firebase",
    // FCPA_500_02_0003: "TODO: Internal Server Error. Something went wrong while updating resource(s) in firebase",
    // FCPA_500_02_0004: "TODO: Internal Server Error. Something went wrong while deleting resource(s) in firebase",
    // FCPA_500_02_0100: "TODO: Internal Server Error. Cannot determine collection path",
    FCPA_500_03: "Unser Service ist derzeit nicht verfügbar. Bitte versuche es später erneut.",
    // FCPA_500_03_0001: "TODO: Internal Server Error. Something went wrong while getting geolocation",
    // FCPA_501: "TODO: Not Implemented",
    // FCPA_502: "TODO: Bad Gateway",
    // FCPA_503: "TODO: Service Unavailable",
    // FCPA_504: "TODO: Gateway Timeout",
    // FCPA_505: "TODO: HTTP Version not supported",
    // FCPA_506: "TODO: Variant Also Negotiates",
    // FCPA_507: "TODO: Insufficient Storage",
    // FCPA_508: "TODO: Loop Detected",
    // FCPA_509: "TODO: Bandwidth Limit Exceeded",
    // FCPA_510: "TODO: Not Extended",
    // FCPA_511: "TODO: Network Authentication Required",
};

const translator = new Error.FErrorTranslator(dictionary);
