import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const CreateOrderPageContent = lazy(() => import("./CreateOrderPageContent"));

function CreateOrderPageInternal() {
    return (
        <DefaultPage>
            <CreateOrderPageContent />
        </DefaultPage>
    );
}

export const CreateOrderPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER)
)(CreateOrderPageInternal);
