import { AppCompany } from "./AppCompany";
import { Variant } from "./CompanyMeta";

export class Contractor {
    public id: string;
    public name: string;
    public city: string;
    public zip: string;
    public street: string;
    public email: string;
    public website: string;
    public variant: Variant;
    public enableOrderRequests: AppCompany["settings"]["enableOrderRequests"];
    constructor(initialValues?: Partial<Contractor>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.city = initialValues?.city ?? "";
        this.zip = initialValues?.zip ?? "";
        this.street = initialValues?.street ?? "";
        this.email = initialValues?.email ?? "";
        this.website = initialValues?.website ?? "";
        this.variant = initialValues?.variant ?? Variant.AGRICULTURAL_CONTRACTOR;
        this.enableOrderRequests = initialValues?.enableOrderRequests ?? false;
    }
}
