import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";
import { environment } from "@/config/config";
import { useAppMeta } from "@/util/customHooks/useAppMeta";

const CampaignsPageContent = lazy(() => import("./CampaignsPageContent"));

function CampaignsPageInternal() {
    const [appMeta] = useAppMeta();
    return (
        <DefaultPage>
            {appMeta?.featureFlags?.campaigns || environment === "local" ? <CampaignsPageContent /> : null}
        </DefaultPage>
    );
}

export const CampaignsPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.PLANNER, Role.ACCOUNTANT)
)(CampaignsPageInternal);
