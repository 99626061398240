import { ActivityPresetPurpose } from "@farmact/model/src/model/AppCompany";
import { ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { produce } from "immer";
import { useRef, useState } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { InternalWorkNoteModalProps } from "@/components/internalWork/InternalWorkGrid/InternalWorkCard/InternalWorkNoteModal/InternalWorkNoteModal";
import { useToastContext } from "@/components/ToastContext";
import { Firebase } from "@/firebase";
import { Deferred } from "@/util/async";

type StopInternalWorkReturn = {
    stop: (endTime?: string) => Promise<void>;
    /**
     * Props that should be passed to `<InternalWorkNoteModal>`
     */
    modalProps: InternalWorkNoteModalProps;
};

// TODO: test this hook
export function useStopInternalWork(options?: {
    waitForDatabaseCommit?: boolean;
    employee?: Employee;
}): StopInternalWorkReturn {
    const { appCompany } = useAppCompanyContext();
    const { employee: uncontrolledEmployee } = useUserContext();
    const { onMessage } = useToastContext();

    const employee = options?.employee ?? uncontrolledEmployee;

    const deferred = useRef<Deferred<void>>();

    const [showInternalNoteModal, setShowInternalNoteModal] = useState(false);
    const lastStopCallTime = useRef<number>();

    const stop = async (endTime?: string) => {
        const now = new Date().getTime();
        if (lastStopCallTime.current && now - lastStopCallTime.current < 1000) {
            return;
        }
        lastStopCallTime.current = now;
        if (!appCompany) {
            return;
        }
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "danger");
            return;
        }

        const shouldAskForNote = ((): boolean => {
            const currentActivity = employee.currentActivity;
            if (!currentActivity) {
                return false;
            }
            if (currentActivity.type !== ActivityType.INTERNAL) {
                return false;
            }
            if (!appCompany?.settings.showNoteQueryForInternalWork) {
                return false;
            }
            if (currentActivity.note) {
                return false;
            }

            const preset = appCompany.settings.internalActivityPresets.find(preset => {
                return preset.id === currentActivity.internalActivityPresetId;
            });
            if (!preset) {
                return false;
            }

            if (preset.purpose === ActivityPresetPurpose.PAUSE) {
                return false;
            }

            return true;
        })();

        if (shouldAskForNote) {
            deferred.current = new Deferred();
            setShowInternalNoteModal(true);
            return deferred.current.promise;
        }

        if (employee.currentActivity) {
            await Firebase.instance().endCurrentActivityForEmployee(employee, appCompany, {
                endTimeOfPreviousActivity: endTime,
                waitForServerResponse: options?.waitForDatabaseCommit,
            });
        }
    };

    const handleSubmit = async (note: string) => {
        setShowInternalNoteModal(false);
        if (!appCompany) {
            return;
        }
        if (!employee) {
            onMessage("Konnte Mitarbeiter nicht identifizieren.", "danger");
            return;
        }

        const localEmployee = produce({ ...employee }, draft => {
            if (!draft.currentActivity) {
                return;
            }
            draft.currentActivity.note = note;
        });

        await Firebase.instance().endCurrentActivityForEmployee(localEmployee, appCompany, {
            waitForServerResponse: options?.waitForDatabaseCommit,
        });

        deferred.current?.resolve();
    };

    const handleDismiss = () => {
        setShowInternalNoteModal(false);
    };

    return {
        stop,
        modalProps: {
            isOpen: showInternalNoteModal,
            onSubmit: handleSubmit,
            onDismiss: handleDismiss,
        },
    };
}
