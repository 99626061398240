import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const EmployeeDetailsPageContent = lazy(() => import("./EmployeeDetailsPageContent"));

function EmployeeDetailsPageInternal() {
    return (
        <DefaultPage>
            <EmployeeDetailsPageContent />
        </DefaultPage>
    );
}

export const EmployeeDetailsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT))(
    EmployeeDetailsPageInternal
);
