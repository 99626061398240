import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const EmployeesTimeTrackingsContent = lazy(() => import("./EmployeesTimeTrackingsPageContent"));

function EmployeesTimeTrackingsInternal() {
    return (
        <DefaultPage>
            <EmployeesTimeTrackingsContent />
        </DefaultPage>
    );
}

export const EmployeesTimeTrackingsPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT)
)(EmployeesTimeTrackingsInternal);
