import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const OfferArchivePageContent = lazy(() => import("./OfferArchivePageContent"));

function OfferArchivePageInternal() {
    return (
        <DefaultPage>
            <OfferArchivePageContent />
        </DefaultPage>
    );
}

export const OfferArchivePage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT))(
    OfferArchivePageInternal
);
