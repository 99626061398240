"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.F_CODE = exports.F_ERROR_PREFIX = void 0;
exports.F_ERROR_PREFIX = `FEIS`;
/* forward stack trace */
var F_CODE;
(function (F_CODE) {
    // ==============
    // GENERAL ERRORS
    // ==============
    // 4xx
    F_CODE["FEIS_400"] = "Bad Request";
    F_CODE["FEIS_401"] = "Unauthorized";
    F_CODE["FEIS_402"] = "Payment Required";
    F_CODE["FEIS_403"] = "Forbidden";
    F_CODE["FEIS_404"] = "Not Found";
    F_CODE["FEIS_405"] = "Method Not Allowed";
    F_CODE["FEIS_406"] = "Not Acceptable";
    F_CODE["FEIS_407"] = "Proxy Authentication Required";
    F_CODE["FEIS_408"] = "Request Timeout";
    F_CODE["FEIS_409"] = "Conflict";
    F_CODE["FEIS_410"] = "Gone";
    F_CODE["FEIS_411"] = "Length Required";
    F_CODE["FEIS_412"] = "Precondition Failed";
    F_CODE["FEIS_413"] = "Payload Too Large";
    F_CODE["FEIS_414"] = "URI Too Long";
    F_CODE["FEIS_415"] = "Unsupported Media Type";
    F_CODE["FEIS_416"] = "Range Not Satisfiable";
    F_CODE["FEIS_417"] = "Expectation Failed";
    F_CODE["FEIS_418"] = "I`m a teapot (RFC 2324, RFC 7168)";
    F_CODE["FEIS_421"] = "Misdirected Request";
    F_CODE["FEIS_422"] = "Unprocessable Entity";
    F_CODE["FEIS_423"] = "Locked (WebDAV; RFC 4918)";
    F_CODE["FEIS_424"] = "Failed Dependency (WebDAV; RFC 4918)";
    F_CODE["FEIS_425"] = "Too Early (RFC 8470)";
    F_CODE["FEIS_426"] = "Upgrade Required";
    F_CODE["FEIS_428"] = "Precondition Required (RFC 6585)";
    F_CODE["FEIS_429"] = "Too Many Requests (RFC 6585)";
    F_CODE["FEIS_431"] = "Request Header Fields Too Large (RFC 6585)";
    F_CODE["FEIS_451"] = "Unavailable For Legal Reasons (RFC 7725)";
    // 5xx
    F_CODE["FEIS_500"] = "Internal Server Error";
    F_CODE["FEIS_501"] = "Not Implemented";
    F_CODE["FEIS_502"] = "Bad Gateway";
    F_CODE["FEIS_503"] = "Service Unavailable";
    F_CODE["FEIS_504"] = "Gateway Timeout";
    F_CODE["FEIS_505"] = "HTTP Version not supported";
    F_CODE["FEIS_506"] = "Variant Also Negotiates";
    F_CODE["FEIS_507"] = "Insufficient Storage";
    F_CODE["FEIS_508"] = "Loop Detected";
    F_CODE["FEIS_509"] = "Bandwidth Limit Exceeded";
    F_CODE["FEIS_510"] = "Not Extended";
    F_CODE["FEIS_511"] = "Network Authentication Required";
})(F_CODE || (exports.F_CODE = F_CODE = {}));
