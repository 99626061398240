import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const BillSettingsPageContent = lazy(() => import("./BillSettingsPageContent"));

function BillSettingsPageInternal() {
    return (
        <DefaultPage>
            <BillSettingsPageContent />
        </DefaultPage>
    );
}

export const BillSettingsPage = withAuthorization(getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT))(
    BillSettingsPageInternal
);
