import { Invitation } from "@farmact/model/src/model/Invitation";
import { Role } from "@farmact/model/src/model/Role";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";

type UseAppInvitationsReturnValue = {
    customerInvitations: Invitation[];
    employeeInvitations: Invitation[];
};

export function useAppInvitations(authUserId: string | undefined): UseAppInvitationsReturnValue {
    const [firebaseInvitations] = useCollectionData(
        authUserId ? Firebase.instance().getAllInvitationsForInvitedAppUserId(authUserId) : undefined,
        [authUserId]
    );

    const employeeInvitations = firebaseInvitations.filter(
        invitation => invitation.role !== Role.CUSTOMER && invitation.role !== Role.NOTHING
    );

    const customerInvitations = firebaseInvitations.filter(invitation => invitation.role === Role.CUSTOMER);

    return {
        customerInvitations,
        employeeInvitations,
    };
}
