import { lazy } from "react";
import { onlyCustomer } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ContractorsOrderRequestPageContent = lazy(() => import("./ContractorsOrderRequestsPageContent"));

function ContractorsOrderRequestPageInternal() {
    return (
        <DefaultPage>
            <ContractorsOrderRequestPageContent />
        </DefaultPage>
    );
}

export const ContractorsOrderRequestPage = withAuthorization(onlyCustomer)(ContractorsOrderRequestPageInternal);
