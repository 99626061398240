import { Role } from "@farmact/model/src/model/Role";
import { lazy } from "react";
import { getAuthorizationGuard } from "@/components/authentication/authorizations";
import { withAuthorization } from "@/components/authentication/Session/withAuthorization";
import { DefaultPage } from "@/components/structure/Page/DefaultPage/DefaultPage";

const ResourceDetailsPageContent = lazy(() => import("./ResourceDetailsPageContent"));

function ResourceDetailsPageInternal() {
    return (
        <DefaultPage>
            <ResourceDetailsPageContent />
        </DefaultPage>
    );
}

export const ResourceDetailsPage = withAuthorization(
    getAuthorizationGuard(Role.OWNER, Role.ADMIN, Role.ACCOUNTANT, Role.PLANNER, Role.EMPLOYEE)
)(ResourceDetailsPageInternal);
