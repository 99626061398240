"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DatevAuthenticationService = void 0;
const OpenAPI_1 = require("../core/OpenAPI");
const request_1 = require("../core/request");
class DatevAuthenticationService {
    /**
     * Returns the DATEV consent page
     * Send a user to the DATEV consent page for connecting DATEV with FarmAct. The consent page will be returned as a URL the user has to open on the device.
     * @returns DatevLoginResponseDto OK
     * @throws ApiError
     */
    static login({ companyId, operatingUnitId, clientId, authRequestId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/datev/auth/login',
            query: {
                'clientId': clientId,
                'authRequestId': authRequestId,
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Remove the connection between DATEV and FarmAct
     * By logging out the connection will be closed between DATEV and FarmAct. All tokens will be revoked and persistent data about the client will be removed.
     * @returns DatevLogoutResponseDto
     * @throws ApiError
     */
    static logout({ companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/datev/auth/logout',
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Get the current DATEV connection status
     * Returns the status of the connection between DATEV and FarmAct. To be used for displaying in the GUI.
     * @returns DatevConnectionStatusResponseDto
     * @throws ApiError
     */
    static checkConnectionStatus({ companyId, operatingUnitId, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/datev/auth/status',
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
            },
        });
    }
    /**
     * Retreive information about an ongoing auth request
     * @returns DatevAuthRequestStatusResponseDto
     * @throws ApiError
     */
    static checkAuthRequestStatus({ companyId, operatingUnitId, id, }) {
        return (0, request_1.request)(OpenAPI_1.OpenAPI, {
            method: 'GET',
            url: '/datev/auth/request',
            query: {
                'companyId': companyId,
                'operatingUnitId': operatingUnitId,
                'id': id,
            },
        });
    }
}
exports.DatevAuthenticationService = DatevAuthenticationService;
